// jshint ignore: start
import React, {useRef, useEffect} from "react"
import Layout from "../components/layout"
import { Typography, Divider } from '@material-ui/core/';
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import { Fade } from '@material-ui/core';
import HomeCoverBig from '../components/covers/homeCoverBig'
import HomeCoverLittle from '../components/covers/homeCoverLittle'
import FeatureSection from '../components/sections/featureSection'
import { GatsbyImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import Helmet from 'react-helmet';
import Seo from '../components/seo';
//import VideoSection from '../components/sections/videoSection'
import ogImage from '../../static/og-image/ogg-image-default.png'
import "./index.css"

const useStyles = makeStyles((theme) => ({
    coverTitle: {

    },
    coverSubtitle: {
      color: 'hsla(100%,100%,75.3%,.8)',
      marginBottom: 20,
      textShadow: '1px 1px #000000'
    },
    sec1: {
      width: '100%',
      height: 710,
    },
    disney: {
      padding: '0 80px',
      boxSizing: 'border-box',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '60px',
      marginTop: 0,
      marginBottom: 0
    },
    section1: {
        width: '100%',
        position: 'relative',
        padding: '80px',
        minHeight: '100vh',
        justifyContent: 'center',
        boxSizing: 'border-box',
        alignItems: 'center'
    },
    secImage1: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        height: '200%',
        zIndex: -1,
        pointerEvents: 'none',
        overflow: 'hidden'
    },
    heading: {
      margin: '0 0 20px',
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '0.8em',
      letterSpacing: 1
    },
    vis: {
       visibility: "hidden"
    }
}));

const AppPage = ({children}) => {
    const classes = useStyles();
    const intl = useIntl()
    const refFromUseRef = useRef();
    const data = useStaticQuery(graphql`
        query {
            coverlogo: file(relativePath: { eq: "site-logo.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 116, placeholder: NONE )
                }
            }
            desktopwater: file(relativePath: { eq: "waterimage.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 1920, placeholder: BLURRED )
                }
            }
            disney: file(relativePath: { eq: "miguelcruz-logos.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 2160, placeholder: NONE )
                }
            }
            outdoor: file(relativePath: { eq: "interior.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 2160, placeholder: BLURRED )
                }
            }
        }
    `)
      const is815 = useMediaQuery(
        json2mq({
          maxWidth: 815,
        }),
      );
      const is630 = useMediaQuery(
        json2mq({
          maxWidth: 630,
        }),
      );
      const lessThen500 = useMediaQuery(
        json2mq({
          maxWidth: 500,
        }),
      );

    useEffect(() => {
        return () => window.removeEventListener('scroll', null);
    }, []);

    const scrollHandler = () => {
        const btn = document.getElementById('elem');
        btn.addEventListener('scroll', window.scrollTo({
            top: refFromUseRef.current.offsetTop,
            behavior: 'smooth',
        }));
    }

    return (
        <Layout>
        <Seo
          title={intl.formatMessage({ id: "title" })}
          description={intl.formatMessage({ id: "description" })}
          lang={intl.locale}
        />
        <Helmet>
          <meta property="og:image" content={'https://www.tanoariacoelho-adrego.com' + ogImage} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:width" content="630" />
          <meta property="og:url" content="https://www.tanoariacoelho-adrego.com" />
          <meta property="og:site_name" content={intl.formatMessage({ id: "title" })} />
          <meta property="og:title" content={intl.formatMessage({ id: "title" })} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={intl.formatMessage({ id: "description" })} />
        </Helmet>
            <div style={lessThen500 ? {padding: '80px 20px 80px 20px', height: 710} : {padding: 80, height: 710}} className={classes.section1}>
                <Fade in={true}>
                    <div>
                        <div style={{color: 'white', maxWidth: '632px'}}>
                        <div style={{width: '42%', maxWidth: '160px', marginBottom: '20px'}}>
                        <GatsbyImage alt="" loading="eager" image={data.coverlogo.childImageSharp.gatsbyImageData} />
                        </div>
                        <div style={{paddingTop: 160}}></div>
                            <h1 style={!is630 ? {display: 'block'} : {fontSize: 32}} className={classes.heading}>{intl.formatMessage({ id: "title" })}<span style={!is815 ? {display: 'block'} : !is630 ? {display: 'block', fontSize: 32} : {display: 'block', fontSize: 32}}> </span></h1>
                            <Typography style={!is815 ? {fontSize: 30} : {fontSize: 20}} className={classes.coverSubtitle} variant="h5" component="h2">
                              {!is630 ? intl.formatMessage({ id: "home.slogan" }) : intl.formatMessage({ id: "home.slogan" })}
                            </Typography>
                            <div style={{ borderRadius: '4px', width: '100%', backgroundColor: 'rgba(0,0,0,0.6)', padding: 20}}>
                              <Typography style={!is630 ? {color: 'red'} : {color: 'red', fontSize: 15, fontWeight: 700}} variant="h6" component="p">
                              </Typography>
                              <Typography style={!is630 ? {color: 'rgba(255,188,0,1)'} : {color: 'rgba(255,188,0,1)', fontSize: 20}} variant="subtitle1" component="p">
                                {intl.formatMessage({ id: "home.slogan_box" })}
                              </Typography>
                            </div>
                        </div>
                        <div style={lessThen500 ? {padding: '0 20px'} : {padding: '0 80px'}} className={classes.disney}>

                            <div className="scroll-down" style={{width: '35px', height: '35px', position: 'absolute', right: '35px', bottom: '35px', top: 0}}>
                                <span role="button" aria-label="Click" tabIndex={0} id="elem" style={{cursor: 'pointer', outline: 'none'}} onClick={scrollHandler} onKeyDown={scrollHandler}><svg width="35" height="35" fill="white" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><rect width="48" height="48" fill="none"></rect><path d="M36.63,18.37a1.37,1.37,0,0,1,2.15.37,1.7,1.7,0,0,1-.3,2.06L25.4,32.64a1.37,1.37,0,0,1-1.85,0l-13-11.84a1.71,1.71,0,0,1-.29-2.06,1.37,1.37,0,0,1,2.15-.37l12.11,11ZM24.25,31.42a.38.38,0,0,1,.46,0l-.23-.21ZM11.71,19.55s0,.06,0,0Zm25.61,0h0Z"></path></svg></span>
                            </div>
                        </div>
                    </div>
                </Fade>
                <div className={classes.secImage1}>
                { lessThen500 ? <HomeCoverLittle className={classes.sec1}/> : <HomeCoverBig className={classes.sec1}/> }
                </div>
            </div>
            <div ref={refFromUseRef} id="yey" style={lessThen500 ? {padding: '80px 20px'} : {padding: '80px'}} className={classes.section1}>
              <div style={{padding: '20px 0', maxWidth: 990, margin: '0 auto', textAlign: 'center'}}>
                <h2 style={lessThen500 ? {fontSize: '40px'} : {fontSize: '40px'}}  className={classes.heading}>{intl.formatMessage({ id: "home.center_title" })}</h2>
                <Typography style={{color: 'hsla(100%,100%,75.3%,.8)', marginBottom: 20}} variant="h5" component="h2">
                  {intl.formatMessage({ id: "home.center_description" })}
                </Typography>
                <div style={{maxWidth: 900, margin: '0 auto', marginBottom: 20}}>
                {/*
                  <VideoSection
                      videoSrcURL={"https://www.youtube.com/embed/9gESeRaasrE?rel=0"}
                      videoTitle={"Tanoaria S. Pinto Coelho & Adrego"}
                  />
                */}

              </div>
                <Divider />
                <div style={{padding: '20px 0', maxWidth: 728, margin: '0 auto'}}> <GatsbyImage alt="" loading="eager" image={data.outdoor.childImageSharp.gatsbyImageData} /></div>

              </div>
            </div>
            <FeatureSection
              image={data.desktopwater.childImageSharp.gatsbyImageData}
              title={intl.formatMessage({ id: "home.featured_section_title" })}
              subtitle={intl.formatMessage({ id: "home.featured_section_description" })}
              height={415}
              opacity={0.2}
              showButton={false}
              titleButton={intl.formatMessage({ id: "home.featured_section_bottom" })}
              linkButton="#"
              >
            </FeatureSection>
            <div style={{padding: '20px 0', maxWidth: 990, margin: '0 auto', textAlign: 'center'}}>
             
            </div>
      </Layout>
    )
}

export default AppPage